.modal-style{
    background-color: rgba(0, 0, 0, 0.9);
}
.modal-style-item{
    color: #fff;
    background: #191D31;
    border-radius: 10px;
    border: 2px solid #56647C4D;
    display: flex;
    align-items: center;
}
.modal-style-item img{
    margin-top: -70px;
    border: 10px solid  rgba(0, 0, 0, 1.0);
    border-radius: 50%;
}
.modal-header {
    border-bottom: #000;
}
.modal-footer{
    border-top: #000;
    padding: 0rem;
}
.form-control {
    background-color: #1F2740;
    color: #fff;
    border: 1px solid #1F2740;
    padding: 15px;
}
.form-control::placeholder{
    color: #61708A;
}
