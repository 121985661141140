.section-Analytics h3{
    font-size: 20px;
    color: #BFBFD2;
    font-weight: 400;
}
.section-Analytics__item{
    background: #191D31;
    border: 1px #20293D;
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    padding-top: 95px;
    padding-bottom: 100px;
}
.section-Analytics__item img {
    width: 50%;
}

.section-Analytics__item button {
    padding: 15px 25px;
    border-radius: 10px;
    font-size: 18px;
    font-family: 'Aktiv Grotesk Ex';
    font-weight: 400;
}
.section-Analytics__item p {
    font-family: 'Aktiv Grotesk Ex';
    color: #BFBFD2;
    font-size: 22px;
    font-weight: 400;
}
.css-1qltlow-MuiTabs-indicator {
    background-color: #eeff41 !important;
}
.link-tab{
    font-family: Work sans, sans-serif;
    font-size: 14px !important;
    font-weight: 500 !important;
    text-transform: capitalize;
    color: #BFBFD2 !important;
}
.link-tab.Mui-selected {
     color: #BFBFD2 !important;
     border-bottom: solid 1px #eeff41;
     font-size: 14px !important;
     font-weight: 500;
}
.css-19kzrtu{
    padding: 0px !important;
    padding-top: 24px !important;
}
.size-img{
    width: 85%;
}
.bg-alert{
    color: #eeff41;
    background-color: #d2b48c;
}


/* Cards */
.card-analytic{
    background-color: #191D31;
    border: 1px solid #20293D;
    padding: 20px;
    border-radius: 10px;
}
.card-analytic h4{
    font-family: 'Aktiv Grotesk Ex';
    font-size: 16px;
    font-weight: 500;
    padding-bottom: 10px;
}