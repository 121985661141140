.obs-estension h2{
    font-size: 20px;
    font-weight: 400;
    font-family: 'Aktiv Grotesk Ex';
    color: #BFBFD2;
}
.obs-estension__item {
    background: #191D31;
    background-image: url('../../img/Path\ 3.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: 10px;
    background-position-y: 30px;
    border-radius: 10px;
    border: 1px #20293D;
    padding: 25px;
}
.obs-estension__item h3{
    font-size: 20px;
    font-weight: 400;
    font-family: 'Aktiv Grotesk Ex';
}
.obs-estension__item p{
    color: #BFBFD2;
    font-weight: 400;
}
.obs-estension__item button{
    padding: 19px 35px;
    font-weight: 400;
    font-family: 'Aktiv Grotesk Ex';
    border-radius: 10px;
    font-size: 18px;
}
.obs-estension__col-items-1{ 
    width: 150px;
    padding-right: 10px;
}
.obs-estension__col-items-2{
    width: calc(100% - 150px);
    padding-left: 10px;
}

@media (max-width: 570px) {
    .obs-estension__col-items-1, .obs-estension__col-items-2 {
      width: 100%;
    }
    .obs-estension__col-items-1 img {
        width: 100px;
        margin-bottom: 20px;
    }
    .obs-estension__item button{
        padding: 10px 35px;
        font-weight: 400;
        font-family: 'Aktiv Grotesk Ex';
        border-radius: 10px;
        font-size: 14px;
    }
  }