.SetPayoutMethod-bg {
    background: #191D31;
    border: 1px #191D31;
    border-radius: 10px;
    padding: 20px;
    align-items: center;
}

.SetPayoutMethod-bg h3 {
    font-size: 20px;
    font-weight: 500;
    font-family: 'Aktiv Grotesk Ex';
}

.SetPayoutMethod-bg img {
    background-color: #20293D;
    border-radius: 100%;
    padding: 15px;
    width: 70px;
}

@media (max-width: 700px) {
    .SetPayoutMethod-bg button {
        margin-top: 20px;
        width: 100%;
    }
}