.category-card{
    display: flex;
    border: 1px dashed rgba(238, 255, 65, 0.3);
    border-radius: 15px;
    backdrop-filter: blur(0px);
    min-height: 300px;
    height: 100%;
    padding: 30px;
    gap: 30px;
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: center;
}
/* .category-card__body{
    
} */
.category-card.vertical {
    flex-direction: row;
    padding-top: 270px;
    background-position: top;
    background-size: 110% auto;
}

@media (max-width: 767px) {
    .category-card{
        flex-direction: row;
        padding-top: 270px;
        background-position: top;
        background-size: 150% auto;
    }
}
@media (min-width: 768px) {
    .category-card.horizontal .category-card__body{
        width: 70%;
    }
}