.modal-notifications{
    background-color: #2A3B50;
    box-shadow: -12px 16px 50px 0px #000;
    max-width: 450px;
    width: 100%;
    right: 20px;
    position: absolute;
    top: 20px;
    border-radius: 20px;
    height: calc(100vh - 40px);
}
.modal-notifications__header{
    display: flex;
    align-items: center;
    padding: 20px;
    justify-content: space-between;
    border-bottom: 2px solid #364961;
}
.modal-notifications__header h4{
    font-family: 'Aktiv Grotesk Ex';
    font-size: 20px;
    color: #fff;
    font-weight: 500;
    margin: 0px;
}
.modal-notifications__header svg{
    width: 28px;
    height: 28px;
    color: #8796B0;
}
.modal-notifications__header .close-icon{
    color: #8796B0;
    width: 28px;
    height: 28px;
    border: 1px solid #8796B0;
    border-radius: 100%;
    font-size: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.modal-notifications__header .close-icon svg{
    width: 18px;
}


.modal-notifications h6, .btn-link{
    font-family: 'Aktiv Grotesk Ex';
    color: #8796B0;
    font-size: 14px;
    font-weight: 400;
    margin: 0px;
}

.btn-link{
    color: #EEFF41;
    font-weight: 300;
}

.cards-notifications {
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 200px);
    padding-right: 10px;
}
.card-notification {
    border: 2px solid #364961;
    padding: 15px;
    border-radius: 10px;
    margin-bottom: 8px;
}
.card-notification.forRead {
    background-color: #364961;
}

.cards-notifications::-webkit-scrollbar {
    width: 10px;
}

.cards-notifications::-webkit-scrollbar-track {
    background: #191D31;
    border-radius: 161px;
}

.cards-notifications::-webkit-scrollbar-thumb {
    border-radius: 161px;
    background: #8796B0;
    width: 5px;
    border: solid 2px #191D31;
}

.cards-notifications::-webkit-scrollbar-thumb:hover {
    background: #555;
}

@media (max-width: 520px) {
    .modal-notifications{
        right: 0;
        top: 90px;
        border-radius: 0px;
        max-width: 100%;
        height: calc(100vh - 90px);
    }
    .cards-notifications{
        height: calc(100vh - 270px);
    }
}