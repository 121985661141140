.contact-form{
    padding: 20px;
    padding-top: 35px;
    padding-bottom: 35px;
    border: 2px solid rgba(86, 100, 124, 0.3);
    background-color: #191D31;
    box-shadow: 0px 4px 100px 0px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
}
.contact-form h3 {
    font-size: 26px;
    font-weight: 400;
    font-family: 'Aktiv Grotesk Ex';
}
.contact-form input, .contact-form input:focus,
.contact-form textarea, .contact-form textarea:focus{
    background-color: #1F2740;
    color: #fff;
    border: #1F2740;
    font-size: 14px;
    font-weight: 400;
    padding: 10px 20px;
    border-radius: 10px;
    font-family: 'Aktiv Grotesk Ex';
}
.contact-form input::placeholder, .contact-form textarea::placeholder{
    color: #61708A;
    font-size: 14px;
    font-weight: 400;
}
.contact-form label {
    font-size: 14px;
    color: #BFBFD2;
    font-family: Work Sans, sans-serif;
}
.btn-primary{
    font-size: 16px;
    font-weight: 600;
    font-family: Aktiv Grotesk Ex;
}



/*
* Form control
*/

.form-control.error{
    border: 1px solid rgba(255, 76, 76, 0.7);
    box-shadow: 0px 4px 26px 0px rgba(255, 76, 76, 0.2);
}
.form-control.error + .danger-help{
    color: #FF4C4C;
    font-family: Work Sans;
    font-size: 12px;
    font-weight: 500;
    line-height: 26px;
    text-align: left;
}
.form-label.xs, .form-label.xs span{
    font-size: 14px;
}

.register-complete-form{
    position: relative;
    padding-bottom: 60px;
}
.bottom-register{
    position: absolute;
    bottom: 8px;
    right: 0;
}