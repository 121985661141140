.main-dashboard{
    width: calc(100% - 366px);
    padding: 25px 15px;
}
.main-dashboard h2{
    font-family: 'Aktiv Grotesk Ex';
    font-weight: 600;
    font-size: 30px;
}
.aside-dashboard{
    padding: 25px;
    width: 30%;
    max-width: 366px;
}
.sidebar-dashboard{
    background-image: url(../../img/dashboard/background-path-sidebar.png);
    background-size: cover;
    background-position: center center;
    background-color: #20293D;
    border-radius: 26px;
    box-shadow: 0px 10px 28px 0px rgba(18, 18, 36, 0.6);
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 50px);
}

.sidebar-dashboard .sidebar-logo{
    width: 85px;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
}
.sidebar-dashboard .sidebar-logo img{
    width: 85px;
}
.sidebar-dashboard .navbar ul{
    list-style: none;
    padding-left: 20px;
    padding-right: 20px;
    margin: 0px;
    width: 100%;
}
.sidebar-dashboard .navbar li{
    width: 100%;
    margin-bottom: 20px;
}
.sidebar-dashboard .navbar a{
    padding: 20px;
    background-color: transparent;
    display: flex;
    color: #fff;
    width: 100%;
    font-family: 'Aktiv Grotesk Ex';
    font-weight: 400;
    font-size: 16px;
    border-radius: 13px;
    text-decoration: none;
    transition: all .5s;
    box-shadow: none;
    gap: 10px;
}
.sidebar-dashboard .navbar a:hover, 
.sidebar-dashboard .navbar a.active{
    background-color: #60A9ED;
    box-shadow: 0px 10px 28px 0px rgba(18, 18, 36, 0.6);
}

@media (min-width: 992px) {
    .aside-dashboard{
        animation: none !important;
    }
}
@media (max-width: 991px) {
    .main-dashboard{
        width: 100%;
    }
    .aside-dashboard{
        display: none;
        position: fixed;
        top: 0px;
        left: 0px;
        min-height: 100vh;
        width: 100%;
        padding: 0px;
        z-index: 1000;
        max-width: 200%;
    }
    .aside-dashboard.active{
        display: block;
    }
    .aside-dashboard .sidebar-dashboard{
       border-radius: 0px;
       min-height: 100vh;
       max-height: 100vh;
       overflow-y: auto;
    }
}