.tabs-sponsorship{
    border-bottom: 1px solid #20293D;
}
.tabs-sponsorship .MuiTabs-indicator{
    background-color: #EEFF41;
}
.tab-sponsorship{
    font-family: Work Sans, sans-serif;
    font-size: 14px;
    font-weight: 500;
    text-transform: capitalize !important;
    color: #8796B0 !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
}
.tab-sponsorship.Mui-selected {
    color: #fff !important;
    border-bottom: solid 1px #EEFF41;
    background: linear-gradient(to top, rgba(238, 255, 65, .1) 25%, rgba(18, 18, 36, 1) 75%);
}
