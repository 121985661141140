.my-profile__picture{
    width: 238px;
    height: 238px;
    border-radius: 25px;
    object-fit: cover;
    overflow: hidden;
    margin-bottom: 20px;
    position: relative;
}
.my-profile__picture img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.my-profile__picture .btn-upload-image{
    position: absolute;
    bottom: 10px;
    right: 10px;
    width: 56px;
    height: 56px;
    padding: 0px;
}
.my-profile__picture .btn-remove-image{
    position: absolute;
    top: 10px;
    right: 15px;
}
.btn-remove-image{
    font-family: Work Sans, sans-serif;
    font-size: 14px;
    border-radius: 12px;
    padding: 14px 20px;
    color: #fff;
    border: 1px solid rgba(255, 255, 255, 0.1);
    background-color: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(29.9px);
}

.my-profile__info{
    width: calc(100% - 238px);
}
.my-profile-info h1{
    font-family: 'Aktiv Grotesk Ex';
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 10px;
}
.my-profile-info p.email, .data-profile h6, .my-profile-info h5, .data-profile p{
    font-family: Work Sans, sans-serif;
    color: #BFBFD2;
    font-weight: 400;
    font-size: 14px;
}
.data-profile h6{
    font-size: 12px;
    margin-bottom: 10px;
}
.data-profile p{
    font-size: 14px;
    color: #fff;
}
.data-profile .data{
    width: calc(100% - 31px);
}
.data-profile svg{
    color: #BFBFD2;
    width: 21px;
    height: 21px;
}
.data-profile{
    display: flex;
    gap: 10px;
    width: 100%;
}

@media (max-width: 767px) {
    .my-profile__info {
        width: 100%;
    }
}