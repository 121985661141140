.form-forgot__password{
    margin-top: 200px;
    margin-bottom: 100px;
    padding: 35px;
    border-radius: 20px;
    align-items: center;
    background: #191D31;
    border: 2px rgba(86, 100, 124, 0.3);
    border-radius: 20px;
    padding: 40px;
}
.form-password__item{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}
.form-forgot__password h1{
    font-size: 28px;
    font-weight: 400;
    font-family: 'Aktiv Grotesk Ex';
    text-align: center;
}
.form-forgot__password p {
    font-size: 18px;
    text-align: center;
}
.form-forgot__password img {
    width: 100px;
    margin-top: -98px;
}
.form-forgot__password button {
    padding: 20px 60px;
}
.form-forgot__password input, .form-forgot__password input:focus{
    padding-top: 10px;
    padding-bottom: 10px;
    background: #1F2740;
    border: 1px #61708A;
    font-size: 14px;
    color: #fff;
    font-family: 'Aktiv Grotesk Ex';
}
.form-forgot__password{
    font-size: 14px;
    font-weight: 400;
    font-family: Work Sans, sans-serif;
}
.form-forgot__password input::placeholder{
    color: #61708A;
    font-size: 14px;
    font-weight: 400;
}