.login-form:not(.no-box) {
    padding: 20px;
    padding-left: 35px;
    padding-right: 35px;
    /* margin-top: 196px; */
    /* margin-bottom: 196px; */
    border: 2px rgba(86, 100, 124, 0.3);
    background: #191D31;
    border-radius: 20px;
    box-shadow: 0px 4px 100px 0px rgba(0, 0, 0, 0.25);
}
.login-form .logo-form{
    width: 120px;
    margin-top: -98px;
    margin-bottom: 19px;
}
.login-form label{
    color: #BFBFD2;
    font-size: 14px;
    font-weight: 400;
    font-family: Work Sans, sans-serif;
}
.login-form input, .login-form input:focus, .login-form select, .login-form select:focus, .login-form textarea, .login-form textarea:focus {
    font-size: 14px;
    background: #1F2740;
    color: #fff;
    border: #191D31;
    border-radius: 10px;
    padding: 12px;
    font-family: 'Aktiv Grotesk Ex';
}
.login-form input::placeholder, .login-form select::placeholder, .login-form textarea::placeholder {
    color: #61708A;
}
.login-form h1, .login-form h2 {
    font-size: 28px;
    font-family: 'Aktiv Grotesk Ex';
    margin: 0rem;
}

.login-form a{
    font-family: Work Sans, sans-serif;
    font-size: 16px;    
}
.btn-primary{
    font-size: 16px;
    font-weight: 600;
    font-family: 'Aktiv Grotesk Ex';
}

/* @media (max-width: 767px) {
    .login-form {
        margin-top: 50px;
        margin-bottom: 50px;
    }
    .login-form .logo-form{
        width: 120px;
        margin-top: -98px;
        margin-bottom: 19px;
    }
} */