.card-sponsor{
    background-color: #191D31;
    border: 1px solid #20293D;
    border-radius: 10px;
    padding: 20px;
}
.card-sponsor__header{
    padding-bottom: 20px;
}
.card-sponsor__header img{
    width: 60px;
}
.card-sponsor__body h4{
    color: #fff;
    font-family: 'Aktiv Grotesk Ex';
    font-size: 20px;
    margin-bottom: 15px;
}
.card-sponsor__body p{
    font-family: Work Sans, sans-serif;
    font-size: 12px;
    color: #BFBFD2;
}
[class^="btn-dash-"], [class*="btn-dash-"] {
    font-family: 'Aktiv Grotesk Ex';
    font-size: 14px;
    color: #fff;
    padding: 12px 30px;
    border-radius: 10px;
}
.btn-dash-primary{
    background-color: #60A9ED;
}
.btn-dash-secondary{
    background-color: #2E334A;
}
.link-info{
    font-family: 'Aktiv Grotesk Ex';
    color: #8796B0 !important;
    font-size: 12px;
    display: flex;
    align-items: center;
    text-decoration: none;
}
