/* 
** Tamaño de fuentes 
*/
.fs-14{
    font-size: 14px;
}
.fs-12{
    font-size: 12px;
}
.fs-11{
    font-size: 11px;
}
/* Final Tamaño de fuentes */

.header-dashboard{
    border-bottom: 1px solid #2A3B50;
}
.body.dashboardPage{
    background-color: #121224;
    background-repeat: repeat;
    background-size: 100% auto;
    color: var(--text-color-theme);
    min-height: 100vh;
    min-width: 100%;
}
.btn-hdashboard{
    background-color: #20293D;
    border-radius: 13px;
    display: flex;
    align-items: center;
    color: #8796B0;
    height: 52px;
    text-align: center;
    position: relative;
}
.btn-hdashboard__notify .notify{
    position: relative;
}
.btn-hdashboard__notify .notify::before{
    content: " ";
    width: 9px;
    height: 9px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -1px;
    background-color: #D7E926;
    box-shadow: 0px 3px 6px 0px rgba(238, 255, 65, 0.6);
    border-radius: 100%;
}
.btn-hdashboard:hover{
    background-color: #60A9ED;
}
.icon-profile{
    width: 40px;
    height: 40px;
    background-color: #101422;
    border: 1px solid #56647C;
    border-radius: 10px;
    display: inline-block;
    color: #fff;
    overflow: hidden;
}

.dropdown-menu__dashboard .MuiPaper-root {
    background: #2A3B50;
    border-radius: 10px;
    min-width: 280px;
}
.dropdown-menu__dashboard, 
.dropdown-menu__dashboard li, 
.dropdown-menu__dashboard a, 
.dropdown-menu__dashboard p{
    font-family: 'Aktiv Grotesk Ex';
    color: #fff;
    font-size: 14px;
    font-weight: 300;
}

.dropdown-menu__dashboard .menu-profile__email,
.dropdown-menu__dashboard .menu-profile__link{
    font-family: Work Sans, sans-serif;
    color: #8796B0;
    font-size: 13px;
    font-weight: 500;
}
.dropdown-menu__dashboard .menu-profile__link{
    font-size: 12px;
    color: #D7E926;
}
.dropdown-menu__dashboard li{
    border: none;
}
.dropdown-menu__dashboard li > span.border-bottom{
    display: inline-flex;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
}

.dropdown-menu__dashboard li:first-child, .dropdown-menu__dashboard li > span.border-bottom{
    border-bottom: 2px solid #364961 !important;
}

.dropdown-menu__dashboard li:last-child > span.border-bottom{
    border: none !important;
}
.dropdown-menu__dashboard p{
    margin: 0px;
}
.dropdown-menu__dashboard .menu-profile__image {
    width: 40px;
    height: 40px;
    border: 1px solid #56647C;
    object-fit: cover;
    border-radius: 5px;
    overflow: hidden;
    margin-right: 10px;
}
.menu-item .icon{
    margin-right: 10px;
}

.title-dashboard{
    color: #BFBFD2;
    font-family: 'Aktiv Grotesk Ex';
    font-weight: 400;
    font-size: 20px;
    padding-top: 20px;   
    padding-bottom: 20px;
    margin: 0px;   
}

/* alerts */

.alert-polar .MuiAlert-message{
    font-family: Work Sans, sans-serif;
    font-size: 14px;
    font-weight: 500;
}
.alert-polar.MuiAlert-colorWarning{
    background-color: rgba(238, 255, 65, 0.1);
    border: 1px dashed rgba(238, 255, 65, 0.5);
    color: #EEFF41;
}

.bg-dash-primary{
    background-image: url(../img/dashboard/bg-dash-primary.png);
    background-size: cover;
    background-position: center center;
    background-color: #20293D;
    box-shadow: 0px 10px 28px 0px rgba(18, 18, 36, 0.6);
}
.bg-gray-primary{
    background-color: #2A3B50;
}
.bg-gray-secondary{
    background-color: #1F2740;
}


/* Tables */

.table-dash-polar tr{
    border-bottom: solid 7px #121224;
}
.table-dash-polar tbody tr{
    overflow: hidden;
    border-radius: 10px;
}
.table-dash-polar tbody td:first-child{
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}
.table-dash-polar tbody td:last-child{
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}
.table-dash-polar tbody td{
    background-color: #191D31;
}
.table-dash-polar th{
    font-family: Work Sans, sans-serif;
    font-size: 12px;
    font-weight: 500;
    color: #8796B0;
    border: none;
    padding: 5px 10px;
}
.table-dash-polar td{
    padding: 10px;
    font-family: Work Sans, sans-serif;
    font-size: 12px;
    font-weight: 400;
    color: #fff;
    border: none;
}

/* Chips */
.chip-polar.MuiChip-colorWarning{
    background-color: rgba(228, 168, 39, 0.2);
    border: none;
    color: #E4A827;
}
.chip-polar.MuiChip-colorSuccess{
    background-color: rgba(33, 167, 37, 0.2);
    border: none;
    color: #21A725;
}


.adorment-input {
    background-color: #1F2740;
    border-radius: 10px;
    position: relative;
    padding-right: 50px;
}
.adorment-input svg {
    background: #2A3B50;
    padding: 10px;
    font-size: 45px;
    position: absolute;
    right: 5px;
    top: 5px;
    border-radius: 8px;
}

.icon-polar{
    display: inline-flex;
    padding: 20px;
    background: #20293D;
    border-radius: 100%;
    object-fit: cover;
}
.icon-polar svg, .icon-polar img{
    width: 45px;
    height: 45px;
    object-fit: cover;
}
.icon-polar.error, .icon-polar.success{
    padding: 0px;
}
.icon-polar.success svg, .icon-polar.success img
.icon-polar.error svg, .icon-polar.error img{
    width: 85px;
    height: 85px;
    object-fit: cover;
}

.chip-polar {
    align-items: center;
}
.chip-polar .MuiChip-icon{
    background-color: transparent;
    padding: 0px;
    width: 24px;
    height: 24px;
    border-radius: 100%;
}

.toggle-menu{
    border: 1px solid #485468;
    border-radius: 10px;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.close-toggle-menu{
    width: 40px;
    height: 40px;
    margin-top: 20px;
    margin-left: 20px;
    border: 1px solid #8796B0;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.5;
    font-size: 20px;
    padding-top: 6px;
    padding-bottom: 6px;
}

@media (min-width: 992px) {
    .toggle-menu, .close-toggle-menu{
        display: none;
    }
}