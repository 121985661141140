.card-earning p, .card-earning h3, .card-earning h4{
    margin: 0px;
    padding-top: 5px;
    padding-bottom: 5px;
}
.card-earning h3, .card-earning h4{
    font-family: 'Aktiv Grotesk Ex';
    font-weight: 600;
    color: #fff;
}
.card-earning h3{
    font-size: 36px;
}
.card-earning h4{
    font-size: 30px;
}
.card-earning p{
    font-family: Work Sans, sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #BFBFD2;
}

.card-earning-secondary{
    background-color: #191D31;
    border: solid 1px #20293D;
    border-radius: 10px;
    padding: 15px 20px;
}
.card-earning-secondary h4{
    font-family: 'Aktiv Grotesk Ex';
    font-weight: 400;
    color: #fff;
    font-size: 16px;
}
.card-earning-secondary .price{
    font-family: 'Aktiv Grotesk Ex';
    font-weight: 600;
    font-size: 20px;
    color: #BFBFD2;
}