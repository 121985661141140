.settings-bg{
    background: #191D31;
    border: #20293D;
    border-radius: 10px;
    padding: 30px;
    align-items: center;
}
.settings-bg img {
    background: #20293D;
    border-radius: 40%;
    padding: 10px;
}
.settings-bg h3{
    font-size: 20px;
    font-weight: 400;
    font-family: 'Aktiv Grotesk Ex';
}
.settings-bg button{
    padding: 15px 30px;
}

.settings-bg-item{
    background: #191D31;
    border: #20293D;
    border-radius: 10px;
    padding: 30px;
    align-items: center;
}
.settings-bg-item img {
    background: #20293D;
    border-radius: 40%;
    padding: 10px;
}
.settings-bg-item h3{
    font-size: 20px;
    font-weight: 400;
    font-family: 'Aktiv Grotesk Ex';
}
.polar-setting__item{
    background: #2E334A;
    border: #2E334A;
    border-radius: 10px;
}

.settings-bg-item__2{
    background: #191D31;
    border: #20293D;
    border-radius: 10px;
    padding: 30px;
    align-items: center;
}
.settings-bg-item__2 img {
    background: #20293D;
    border-radius: 40%;
    padding: 10px;
}
.settings-bg-item__2 h3{
    font-size: 20px;
    font-weight: 400;
    font-family: 'Aktiv Grotesk Ex';
}

.setting__item-2{
    border-radius: 10px;
    background: #302234;
    color: #FF4D4D;
    border: 1px #302234;
}

.switch-polar {
    background: #EEFF4B !important;
    border-radius: 10px;
    padding: 10px 35px !important;
}

.switch-polar .MuiSwitch-thumb {
    border-radius: 25%;
    width: 20px;
    height: 20px;
}
