.modal-polar{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 520px;
    background-color: #191D31;
    border: 2px solid rgba(86, 100, 124, 0.3);
    border-radius: 20px;
    padding: 20px;   
}
.modal-polar h3{
    font-family: 'Aktiv Grotesk Ex';
    font-size: 20px;
    font-weight: 500;
}
.modal-polar h2{
    font-family: 'Aktiv Grotesk Ex';
    font-size: 30px;
    font-weight: 600;
}

@media (max-width: 767px) {
    .modal-polar{
        left: 0px;
        top: 0px;
        width: 100%;
        max-width: 100%;
        transform: none;
        max-height: 100%;
        overflow-y: auto;
        border-radius: 0px;
    }
}