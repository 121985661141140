.from-sign__up {
    padding: 20px;
    padding-left: 35px;
    padding-right: 35px;
    margin-top: 196px;
    margin-bottom: 196px;
    border: 2px rgba(86, 100, 124, 0.3);
    background: #191D31;
    border-radius: 20px;
    box-shadow: 0px 4px 100px 0px rgba(0, 0, 0, 0.25);
}
.from-sign-up__Radio-group img {
    width: 100px;
    margin-top: -70px;
    margin-bottom: 20px;
}
.from-sign-up__Radio-group{
    width: 100px;
    margin-top: -70px;
}
.form-control__label{
    background-color: #1F2740;
    border: 1px #20293D;
    margin-left: 0px !important;
    margin-right: 0px !important;
    margin-bottom: 15px;
    border-radius: 10px;
    font-size: 40px;
    width: 100%;
}
.group-label-polar{
    width: 100%;
}
.form-control__label .MuiFormControlLabel-label {
    font-size: 20px;
    width: 100%;
}
.form-control__label .helpText{
    font-family: Work Sans, sans-serif;
    font-size: 12px;
    font-weight: 400;
    color: #BFBFD2;
    width: 100%;
    text-align: right;
}

.icon-label{
    padding: 10px;
    background-color: #2E334A;
    border-radius: 100%;
    margin-right: 16px;
    text-align: center;
    display: flex;
}
.icon-label img{
    width: 30px;
}

.lateral-register{
    background-image: url(../../img/sign-up/background-lateral.png);
    background-size: 150% 150%;
    background-position: center center;
    height: 100%;
    border-radius: 20px;
}
.lateral-register img{
    position: relative;
    width: 180px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@media (max-width: 900px) {
    .form-control__label .helpText{
        display: none;
    }
}