.section-ArchiveChat__item{
    background: #191D31;
    padding: 25px;
    border-radius: 10px;
    border: 1px #20293D ;
}
.section-ArchiveChat__item h4 {
    font-size: 20px;
    font-weight: 400;
    color: #fff;
    padding-bottom: 10px;
}

.section-ArchiveChat__item button {
    padding: 18px 30px;
}

@media (max-width:968px) {
    .section-ArchiveChat__item button {
        width: 100%;
    }
    
}

.icon-noBg .icon-label{
    background: none;
    padding: 3px;
}

.label-radio-archive .icon-label{
    background: none;
    width: 91px;
    height: 75px;
    padding: 5px;
}
.label-radio-archive .icon-label img{
    width: 100%;
    height: 100%;
    object-fit: cover; 
    border-radius: 7px;
}
.fs-label__archive .MuiFormControlLabel-label{
    font-size: 14px;
    font-weight: 400;
    font-family: 'Aktiv Grotesk Ex';
    color: #BFBFD2;
}