@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200..1000;1,200..1000&family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');

@font-face {
    font-family: 'Aktiv Grotesk Ex';
    src: url('../fonts/AktivGrotesk/OTF/AktivGrotesk-Thin.otf') format('opentype'),
         url('../fonts/AktivGrotesk/OTF/AktivGrotesk-Thin.otf') format('opentype');
    font-weight: 100;
    font-style: normal;
}
@font-face {
    font-family: 'Aktiv Grotesk Ex';
    src: url('../fonts/AktivGrotesk/OTF/AktivGrotesk-ThinItalic.otf') format('opentype'),
         url('../fonts/AktivGrotesk/OTF/AktivGrotesk-ThinItalic.otf') format('opentype');
    font-weight: 100;
    font-style: italic;
}
@font-face {
    font-family: 'Aktiv Grotesk Ex';
    src: url('../fonts/AktivGrotesk/OTF/AktivGrotesk-Light.otf') format('opentype'),
         url('../fonts/AktivGrotesk/OTF/AktivGrotesk-Light.otf') format('opentype');
    font-weight: 200;
    font-style: normal;
}
@font-face {
    font-family: 'Aktiv Grotesk Ex';
    src: url('../fonts/AktivGrotesk/OTF/AktivGrotesk-LightItalic.otf') format('opentype'),
         url('../fonts/AktivGrotesk/OTF/AktivGrotesk-LightItalic.otf') format('opentype');
    font-weight: 200;
    font-style: italic;
}
@font-face {
    font-family: 'Aktiv Grotesk Ex';
    src: url('../fonts/AktivGrotesk/OTF/AktivGrotesk-Regular.otf') format('opentype'),
         url('../fonts/AktivGrotesk/OTF/AktivGrotesk-Regular.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Aktiv Grotesk Ex';
    src: url('../fonts/AktivGrotesk/OTF/AktivGrotesk-Medium.otf') format('opentype'),
         url('../fonts/AktivGrotesk/OTF/AktivGrotesk-Medium.otf') format('opentype');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Aktiv Grotesk Ex';
    src: url('../fonts/AktivGrotesk/OTF/AktivGrotesk-MediumItalic.otf') format('opentype'),
         url('../fonts/AktivGrotesk/OTF/AktivGrotesk-MediumItalic.otf') format('opentype');
    font-weight: 500;
    font-style: italic;
}
@font-face {
    font-family: 'Aktiv Grotesk Ex';
    src: url('../fonts/AktivGrotesk/OTF/AktivGrotesk-XBold.otf') format('opentype'),
         url('../fonts/AktivGrotesk/OTF/AktivGrotesk-XBold.otf') format('opentype');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'Aktiv Grotesk Ex';
    src: url('../fonts/AktivGrotesk/OTF/AktivGrotesk-XBoldItalic.otf') format('opentype'),
         url('../fonts/AktivGrotesk/OTF/AktivGrotesk-XBoldItalic.otf') format('opentype');
    font-weight: 700;
    font-style: italic;
}
@font-face {
    font-family: 'Aktiv Grotesk Ex';
    src: url('../fonts/AktivGrotesk/OTF/AktivGrotesk-Bold.otf') format('opentype'),
         url('../fonts/AktivGrotesk/OTF/AktivGrotesk-Bold.otf') format('opentype');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'Aktiv Grotesk Ex';
    src: url('../fonts/AktivGrotesk/OTF/AktivGrotesk-BoldItalic.otf') format('opentype'),
         url('../fonts/AktivGrotesk/OTF/AktivGrotesk-BoldItalic.otf') format('opentype');
    font-weight: 600;
    font-style: italic;
}
@font-face {
    font-family: 'Aktiv Grotesk Ex';
    src: url('../fonts/AktivGrotesk/OTF/AktivGrotesk-Black.otf') format('opentype'),
         url('../fonts/AktivGrotesk/OTF/AktivGrotesk-Black.otf') format('opentype');
    font-weight: 900;
    font-style: normal;
}
@font-face {
    font-family: 'Aktiv Grotesk Ex';
    src: url('../fonts/AktivGrotesk/OTF/AktivGrotesk-BlackItalic.otf') format('opentype'),
         url('../fonts/AktivGrotesk/OTF/AktivGrotesk-BlackItalic.otf') format('opentype');
    font-weight: 900;
    font-style: italic;
}

:root{
    --text-color-theme: #fff;
    --bs-border-radius: 10px;
}

/*
* Boostrap Modify
*/
.btn-primary{
    --bs-btn-bg: #60A9ED;
    --bs-btn-border-color: #60A9ED;
}
/* .btn{ */
    /* --bs-btn-font-size: 22px; */
    /* --bs-btn-padding-y: 20px;
    --bs-btn-padding-x: 20px; */
/* } */

.body.homePage, .body.singlePages{
    background-color: #121224;
    background-image: url(../img/fondo-website.png);
    background-repeat: repeat;
    background-size: 100% auto;
    color: var(--text-color-theme);
    min-height: 100vh;
}
.flex-nav{
    display: flex;
    align-items: center;
}
.logo{
    display: flex;
    align-items: center;
    color: #fff;
    z-index: 1001;
    position: relative;
}
.logo img{
    width: 53px;
    margin-right: 10px;
}
.navbar-toggler{
    border: 2px solid #485468;
    border-radius: 20%;
    padding: 5px 10px;
    z-index: 1001;
}
.bi-list{
    font-size: 30px;
    color: #fff;
}
.logo p{
    font-size: 20px;
    color: #fff;
    text-align: center;
    padding: 10px;
}
.text-color{
    color: #8796B0;
    text-decoration: none;
    font-size: 16px;
    border-bottom: 2px solid #2A3B50;
}
.button-color__sign{
    border: 2px  solid#485468;
    color: #fff;
    padding: 10px 30px;
    font-size: 16px;
    font-weight: 400;
}
.button-color__login{
    border: 2px  solid#60A9ED;
    color: #fff;
    padding: 10px 30px;
    font-size: 16px;
    font-weight: 400;
}
.button-color__sign:hover{
    border: 2px  solid#485468;
    color: #485468;
}
.button-color__login:hover{
    border: 2px  solid#60A9ED;
    color: #60A9ED;
}
.text-color__home{
    color: #fff;
    text-decoration: none;
}
.text-color__advertisers{
    color: #485468;
    text-decoration: none;

}
.black-mark{
    display: block;
    border-bottom: 1px solid #000000;
    padding-bottom: 20px;
    margin-bottom: 25px;
    margin-top: 40px;
}
.text-color__Contact{
    color: #485468;
    text-decoration: none;
    font-size: 14px;
}
.Copyright{
    color: #8796B0;
    font-size: 12px;
}
.services{
    color: #8796B0;
    font-size: 14px;
}
.privacy-policy{
    color: #8796B0;
    font-size: 14px;
}
.services{
    color: #8796B0;
    font-size: 12px;
}
.privacy-policy{
    color: #8796B0;
    font-size: 12px;
}

.nav-contact-footer{
    display: flex;
    flex-wrap: wrap;
}
.nav-contact-footer li{
    width: 50%;
}

.navbar-toggler[aria-expanded="true"] i{
    display: none;
}
.navbar-toggler[aria-expanded="true"]{
    border: none;
}
.navbar-toggler[aria-expanded="false"] img{
    display: none;
}

#header{
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1000;
    width: 100%;
    transition: all .2s;
}

@media (max-width: 991px) {
    .navbar-header {
        height: 0%;
        transition: all .5s;
    }
    .navbar-header.show {
        height: 100%;
    }
    .nav-footer__header{
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 100%;
        padding: 20px;
        border-top: dashed 2px #2A3B50;
        display: flex;
        gap: 15px;
    }
    .nav-footer__header a{
        width: 50%;
    }
    .navbar-nav-header{
        padding-top: 20px;
    }
    .navbar-nav-header .nav-item:nth-last-child(1), 
    .navbar-nav-header .nav-item:nth-last-child(2){
        display: none;
    }
    
    .navbar-nav-header a.nav-link{
        color: #8796B0;
        text-decoration: none;
        border-top: dashed 2px #485468;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .navbar-nav-header a.nav-link.active, .navbar-nav-header a.nav-link:hover{
        color: #fff;
    }
    .navbar-header{
        position: fixed;
        width: 100%;
        z-index: 1000;
        background: rgb(18, 18, 36);
        top: 0px;
        left: 0px;
        padding-top: 69px;
        padding-left: 20px;
        padding-right: 20px;

    }

}

@media (min-width:  992px) {
    .nav-footer__header{
        display: none;
    }
    .navbar-nav-header a.nav-link{
        color: #8796B0;
        text-decoration: none;
    }
    .navbar-nav-header a.nav-link.active, .navbar-nav-header a.nav-link:hover{
        color: #fff;
    }
    .navbar-expand-lg .navbar-nav{
        align-items: center;
        gap: 20px;
    }
}


@media (min-width:  768px) {
    .flex-nav{
        display: flex;
        align-items: center;
    }
    .nav-contact-footer{
        flex-wrap: nowrap;
        gap: 30px;
    }
    .nav-contact-footer li{
        width: auto;
    }
    .term-privacy{
        display: flex;
        justify-content: space-between;
    }
    .privacy-policy{
        text-decoration: none;
    }
    .services{
        text-decoration: none;
    }
    .navbar-collapse{
        flex-grow: initial;
    }
}
@media (min-width:  940px) {
    .text-color{
        color: #8796B0;
        text-decoration: none;
        font-size: 16px;
        border-bottom: none;
    }
}



/*
* Utilities
*/
.color-apple{
    color: #D7E926;
}

/*
* General
*/
p{
    font-family: Work Sans, sans-serif;
    font-size: 16px;
    color: rgba(191, 191, 210, 1);
}
h3{
    font-size: 32px;
}
img{
    max-width: 100%;
}
.title-main{
    font-family: 'Aktiv Grotesk Ex';
    font-size: 68px;
    font-weight: 700;
    line-height: 76px;
    text-align: left;
    /* text-underline-position: from-font; */
    /* text-decoration-skip-ink: none; */
}
.logo-into-title{
    width: 77px;
    position: relative;
    top: -8px;
}
.paragraph-main{
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    text-align: left;
}
.footer {
    background-color: rgba(14, 14, 31, 1);
    border-top: 1px solid rgba(64, 64, 107, 1);
    padding-top: 18px;
    padding-bottom: 25px;
}



.breadcrumb {
    font-family: 'Aktiv Grotesk Ex';
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 2px;
}

@media (max-width:784px) {
    .title-main{
        font-size: 40px;
    }
}

.logo-footer{
    width: 80px;
}

.icons-footer{
    width: 26px;
}

.icons-footer img{
    width: 26px;
}