.section-support {
    background: #191D31;
    background-image: url('../../img/help-and-support/bg-mask.png');
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 10px;
    border: 1px #20293D;
}

.title-support {
    font-size: 20px;
    color: #BFBFD2;
    padding-bottom: 20px;
    font-family: 'Aktiv Grotesk Ex';
    font-weight: 400;
}

.section-support p {
    font-family: Work Sans, sans-seri;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    color: #FFFFFF;
}